import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export default styled(Box)`
  position: relative;
  margin: -0.65rem 0 1.5rem 0;
  font-size: 1.375rem;
  font-style: italic;
  font-weight: 300;
`;
