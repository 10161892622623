import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Footer from '../footer/footer';
import BannerList from '../banner-list/banner-list-css';
import LinkButton from '../link-button/link-button-css';
import SubTitle from '../subtitle/subtitle-css';
import LargeText from '../large-text/large-text-css';
import { ContentOverlayBanner, SpotlightBanner } from '../banner';
import renderAst from '../../utils/renderAst';

const UXLandingTemplate = ({
  title,
  subtitle,
  content,
  image,
  examples,
}) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <ContentOverlayBanner image={image} contentAlign="left" orient="left" imagePosition="right" bg="white" fullscreen>
      <h1>{title}</h1>
      <SubTitle>{subtitle}</SubTitle>
      <LargeText>{renderAst(content)}</LargeText>
      <LinkButton to="/engineering/ux#example-list" large="true" primary="true">View showcase</LinkButton>
    </ContentOverlayBanner>
    <BannerList id="example-list">
      {examples.map(({ node }, index) => {
        const thumbnail = {
          src: node.frontmatter.landingImage.childImageSharp.gatsbyImageData,
          alt: node.frontmatter.title,
        };
        const orient = (index % 2 > 0) ? 'left' : 'right';
        const imagePosition = (index % 2 > 0) ? 'right' : 'left';

        return (
          <SpotlightBanner key={node.frontmatter.title} image={thumbnail} contentAlign="left" orient={orient} imagePosition={imagePosition}>
            <h2>{node.frontmatter.navtitle || node.frontmatter.title}</h2>
            <p>{node.frontmatter.description}</p>
            <LinkButton to={node.fields.slug}>Learn More</LinkButton>
          </SpotlightBanner>
        );
      })}
    </BannerList>
    <Footer />
  </>
);

UXLandingTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UXLandingTemplate;
