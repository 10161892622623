import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Metadata from '../components/metadata/metadata';
import UXLandingTemplate from '../components/ux-landing/ux-landing';

UXLandingTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const UXLandingPage = ({ data }) => {
  const { markdownRemark: page, bannerImg, examples } = data;
  const image = {
    src: bannerImg.childImageSharp.gatsbyImageData,
    alt: 'banner',
  };

  return (
    <Layout>
      <Metadata pageData={page.frontmatter} />
      <UXLandingTemplate
        title={page.frontmatter.title}
        subtitle={page.frontmatter.subtitle}
        content={page.htmlAst}
        image={image}
        examples={examples.edges}
      />
    </Layout>
  );
};

UXLandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default UXLandingPage;

export const UXLandingQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        subtitle
        description
      }
    },
    bannerImg: file(relativePath: { eq: "banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    },
    examples: allMarkdownRemark(
      filter: { fields: { slug: { regex: "\/engineering/ux\/.+\/" }}},
      sort: {fields: [frontmatter___weight]}
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            navtitle
            description
            weight
            landingImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
